<template>
  <div class="surface">
    <v-sheet rounded="lg" class="background px-2">
      <v-autocomplete
          v-model="selectedItem"
          :items="airports"
          :search-input.sync="search"
          color="primary"
          hide-no-data
          item-value="gps_code"
          item-text="name"
          placeholder="Start Airfield name to Search"
          clearable
          no-filter
          outlined />
    </v-sheet>
    <v-sheet rounded="lg" class="background my-2 px-2">
      <h3 v-if="selectedAirport">{{ selectedAirport.name.toUpperCase() }} ({{ selectedAirport.gps_code }} /
        {{ selectedAirport.iata_code }}) @ {{ reportTimeStamp | moment('HH:MM DD MMM YYYY') }}</h3>
    </v-sheet>
    <v-sheet rounded="lg" class="background my-2 px-2">
      <h4 v-if="metar">Nearest METAR</h4>
      <div v-if="metar">
        <v-layout row wrap class="px-3 pt-2" v-for="(item, i) in metar" :key="i">
          <v-flex xs12>
            {{ item.raw_text }}
          </v-flex>
          <v-flex xs12 sm3 xl1>
            <v-card max-height>
              <v-card-title>Wind Direction</v-card-title>
              <v-card-subtitle>{{ item.wind.degrees }}°</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs12 sm3 xl1>
            <v-card max-height>
              <v-card-title>Wind Speed</v-card-title>
              <v-card-subtitle>{{ item.wind.speed_kts }} kt</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs12 sm3 xl1>
            <v-card max-height>
              <v-card-title>Visibility</v-card-title>
              <v-card-subtitle>{{ item.visibility.meters }} m</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs12 sm3 xl1 v-if="item.conditions">
            <v-card max-height>
              <v-card-title>WX</v-card-title>
              <v-card-subtitle v-if="item.conditions[0]">{{ item.conditions[0].prefix }}{{
                  item.conditions[0].code
                }}
              </v-card-subtitle>
              <v-card-subtitle v-if="!item.conditions[0]">-</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs12 sm3 xl1>
            <v-card max-height>
              <v-card-title>Cloud</v-card-title>
              <v-card-subtitle v-if="item.clouds">{{ item.clouds[0].code }} {{ item.clouds[0].feet }} ft
              </v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs12 sm3 xl1>
            <v-card max-height>
              <v-card-title>Temperature</v-card-title>
              <v-card-subtitle> {{ item.temperature.celsius }} °C</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs12 sm3 xl1>
            <v-card max-height>
              <v-card-title>Dewpoint</v-card-title>
              <v-card-subtitle>{{ item.dewpoint.celsius }} °C</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs12 sm3 xl1>
            <v-card max-height>
              <v-card-title>Pressure</v-card-title>
              <v-card-subtitle>{{ item.barometer.hpa }} hPa</v-card-subtitle>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </v-sheet>
    <v-sheet rounded="lg" class="background my-4 px-2">
      <h4 v-if="taf">Nearest TAF</h4>
      <v-layout v-if="taf">
        <v-flex v-for="(item, i) in taf" :key="i">
          {{ item.raw_text }}
        </v-flex>
      </v-layout>
    </v-sheet>
  </div>
</template>

<script>
import {AirportHttp} from "../../http/AirportHttp";
import {CheckWxApiHttp} from "@/http/CheckWxApiHttp"
import {mapActions} from "vuex";

export default {
  name: "StationInformation",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      airports: [],
      isLoading: false,
      search: '',
      selectedItem: "",
      selectedAirport: null,
      model: null,
      descriptionLimit: 60,
      reportTimeStamp: null,
      metar: null,
      taf: null,
      suntimes: null,

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 12,
      center: null,
      bounds: null


    }
  },
  methods: {
    ...mapActions("app", ["setLoading"])
    // zoomUpdated (zoom) {
    //   this.zoom = zoom;
    // },
    // centerUpdated (center) {
    //   this.center = center;
    // },
    // boundsUpdated (bounds) {
    //   this.bounds = bounds;
    // }
  },
  watch: {

    async search(val) {
      if (val && val.length < 3) return;
      if (val) {
        await this.setLoading(true)
        try {
          this.airports = await AirportHttp.searchForAirportByQuery(val).then(result => result.data);
        } catch (e) {
          alert(e);
        } finally {
          await this.setLoading(false)
        }
      }
    },

    async selectedItem(newVal) {
      if (newVal) {
        await this.setLoading(true)
        this.reportTimeStamp = new Date().toISOString()

        // get METAR Information
        this.selectedAirport = this.airports.find(airport => {
          return airport.gps_code === newVal
        })

        let latlong = this.selectedAirport.coordinates.split(', ')
        this.selectedAirport.long = latlong[0]
        this.selectedAirport.lat = latlong[1]
        this.center = [latlong[1], latlong[0]]

        await CheckWxApiHttp.getNearestMETAR(this.selectedAirport.lat, this.selectedAirport.long).then(
            response => {
              this.metar = response.data.data
            }
        )

        // get TAF Information
        await CheckWxApiHttp.getNearestTAF(this.selectedAirport.lat, this.selectedAirport.long).then(
            response => {
              this.taf = response.data.data
            }
        )
        await this.setLoading(false)
      }
    }
  },
}
</script>

<style scoped>

</style>