<template>
  <div>
  <station-information :loading="loading" class="my-5" />
<!--  <wx-charts />-->
  </div>
</template>

<script>
import { mapState } from "vuex"
import StationInformation from "@/components/shared/StationInformation";
export default {
  name: "MetDashboard",
  components: { StationInformation },
  computed:{
    ...mapState("app",["loading"])
  }
}
</script>

<style scoped>

</style>